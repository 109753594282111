import React, { useState } from "react";
import "./services.css";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I Offer</span>

      <div className="services__container container grid">
        <div className="services__content">
          <div>
            <i className="uil uil-circle-layer services__icon"></i>
            <h3 className="services__title">
              Web <br /> Developer
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(1)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">Web Developer</h3>
              <p className="services__modal-description">
                Advanced Web Development | Consistent High-Quality Work |
                Enhanced User Experience | Strong Brand Identity
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    User Interface Development: Intuitive and visually appealing
                    interfaces are created for an engaging user experience.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Web Page Development: Robust, responsive web pages are built
                    using the latest technologies, ensuring consistency across
                    devices.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    UX Element Interactions: Interactive UX elements are
                    designed to enhance usability and provide a user-centric
                    experience.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Brand Positioning: Brands are effectively positioned in the
                    digital space with integrated branding elements.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Design and Mockups: Detailed mockups are created for a clear
                    vision and iterative feedback before development.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-icons services__icon"></i>
            <h3 className="services__title">
              Graphic <br /> Designer
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(2)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">Graphic Designer</h3>
              <p className="services__modal-description">
                Extensive Branding Design | High-Quality UI/UX Design |
                Comprehensive Graphic Design Services | Versatile Illustration
                Design
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Branding Design Services: Logos, identifiers, typography,
                    and color schemes are designed to establish a strong brand
                    identity.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    UI/UX Design: User interface (UI) and user experience (UX)
                    designs are created for digital products, ensuring an
                    engaging and intuitive user experience.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Graphic Design Services: Graphic design services for print
                    and digital advertisements are offered, including web ads,
                    social media ads, and printed flyers.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Illustration Design: Illustrations are designed for use in
                    books, magazines, websites, and other mediums, showcasing
                    versatile illustration skills.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-border-alt services__icon"></i>
            <h3 className="services__title">
              Digital <br /> Marketing
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(3)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 3
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">Digital Marketing</h3>
              <p className="services__modal-description">
                Comprehensive Digital Marketing Strategies | Advanced SEM & SEO
                Techniques | Effective Social Media Marketing | Optimized Online
                Advertising Campaigns
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Digital Marketing Strategies: Comprehensive strategies are
                    developed and executed for brands, including identifying
                    target audiences and creating marketing content.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    SEM & SEO Techniques: Advanced techniques are employed for
                    content optimisation, keyword analysis, and pay-per-click
                    advertising to increase traffic and exposure.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Social Media Marketing: Effective marketing on platforms
                    like Facebook and Instagram promotes brands and engages
                    users.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Online Advertising Campaigns: Google Ads, social media ads,
                    and display ads are managed and optimised for better ROI.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
